<template>
  <div class="home">
    <banner></banner>
    <h1>公司简介</h1>
    <el-row :gutter="10">
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <p>
            龙祺（山东）数字科技有限公司成立于 2021 年，注册资金1000万，现有员工106人，业务范围包括软件开发、系统集成、IT咨询和服务、人力外包等业务。公司凭借持续的技术和方案发展创新，赢得了大量客户的广泛信任与支持，公司业务范围、影响力逐步提升。
          </p>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="grid-content bg-purple">
          <p>
            公司有软件研发事业部、网络系统集成部、产品事业部。公司拥有一支专业、团结、高效、敬业的软件研发团队和售后团队，团队人员都具有十年以上从事软件研发和信息系统开发经验的IT
          人才，从业时间长，信息技术开发经验丰富，将成熟的企业管理经验和先进的国内外IT技术相融合，为每一个客户提供专业的管理信息化解决方案，最大限度提高客户满意度。良好的内部竞争机制和丰富系统的内部培训使每一位员工都拥有一份主人翁精神。在全体员工的共同努力下，逐渐形成了“求实、稳健、积极”的企业文化。
          </p>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <p>
            公司以一流IT企业为目标，本着“为客户创造价值”、“为社会创造进步”的经营理念，致力于为广大客户提供及时、周到、专业、优质、一流的产品、技术和服务。公司重视提供完整的解决方案，重视和客户建立长久的合作，始终把满足和超越满足客户要求作为每个员工竭力实现的目标。
          </p>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <p>
            百舸争流千帆竞，长风破浪正当时。公司与山东大学开展深入的合作交流，并将秉承高校深厚的技术底蕴和严谨创新的行为风格，以“专注，专业，专家”的企业精神，以客户的成功为价值和目标，为客户提供专家级的服务，与客户共同成长、共同发展！
          </p>
         
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import banner from '../components/banner.vue'
export default {
  name: "Home",
  components:{
    banner
  }
};
</script>
<style lang="less" scoped>
.home{
  width: 100%;
  height: 100%;
  h1{
    margin: 10px 0;
  }
}

.el-row {
    // margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #E5F3FE;
  }
  .bg-purple {
    background: #E5F3FE;
  }
  .bg-purple-light {
    background: #E5F3FE;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    background-color: #f9fafc;
  }
  .grid-content>p{
    text-indent: 28px;
    padding:10px;
    font-size: 16px;
    margin: 0;
  }
</style>
