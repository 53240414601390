<template>
    <div class="bannerBox">
        <img v-for="(item,i) in items" :key="i" :src="item"  v-show="cur==i">
        <ul class="banner-circle">
            <li :class="cur==i ?'selected':'' "  v-for="(v,i) in items" :key="i"></li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return{
            cur:0,
            timer:null,
            items:[
                require('../assets/img/icon1.jpg'),
                require('../assets/img/icon2.jpg'),
                require('../assets/img/icon3.jpg')
            ]
        }
    },
    mounted(){
        this.play();
    },
    destroyed(){//销毁钩子
        clearInterval(this.timer)//销毁定时器
    },
    methods:{
        play(){
            this.timer=setInterval(this.autoPlay,2000)
        },
        autoPlay(){
            this.cur++;
            if(this.cur>=this.items.length){
                this.cur=0;
            }
        }
    }
}
</script>
<style lang="less" scoped>
.bannerBox{
    // margin: 10px 0 0 0;
    position: relative;
    img{
        width:100%;
        height: 576px;
        background-size: 100% 100%;
    }
    .banner-circle{
        position: absolute;
        bottom:5px;
        left: 50%;
        right: 0;
        color: #fff;
        li{
            display:inline-block;
            background:#fff;
            border-radius: 50%;
            padding:5px;
            margin:2px;
        }
        .selected{
            background: #0B3FD9;
        }
    }
    
}

</style>